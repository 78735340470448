<template>
  <v-dialog persistent v-model="showed" width="850">
    <v-card relative>
      <v-card-title class="headline">
        {{ model._id ? 'Update layout' : 'Create layout' }}
      </v-card-title>
      <div class="close-icon" @click="showed = false">
        <v-icon>fas fa-times</v-icon>
      </div>
      <v-divider class=""></v-divider>
      <v-card-text>
        <v-container fluid class="form-component">
          <v-form>
            <v-row>
              <v-col cols="12">
                <label>Display Condition</label>
                <v-checkbox hide-details="" name="isDefault" label="Is default layout" v-model="model.isDefault" />
              </v-col>
            </v-row>
            <v-row v-for="(i, k) in model.conditions" :key="k">
              <v-col cols="5">
                <label for="Conditions">Conditions</label>
                <v-select
                  clearable
                  v-model="i.column"
                  :items="[
                    { name: 'Number Of Character', value: 'numberOfCharacter' },
                    { name: 'Property Name Length', value: 'propertyNameLength' },
                  ]"
                  item-text="name"
                  item-value="value"
                  hide-details=""
                ></v-select>
              </v-col>
              <v-col cols="4">
                <label for="Relation">Relation</label>
                <v-select
                  clearable
                  v-model="i.relation"
                  item-text="name"
                  item-value="value"
                  :items="[
                    { name: 'Equal', value: 'equal' },
                    { name: 'Greater Than', value: 'greaterThan' },
                    { name: 'Less Than', value: 'lessThan' },
                  ]"
                  hide-details=""
                ></v-select>
              </v-col>
              <v-col cols="2">
                <label for="condition">Condition</label>
                <v-text-field item-text="name" item-value="value" v-model="i.condition" hide-details=""></v-text-field>
              </v-col>
              <v-col cols="1 mt-8">
                <i class="fas fa-trash pointer" @click="clearCondition"></i>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5">
                <label for="">Code</label>
                <v-text-field type="text" name="code" v-model="model.code" />
              </v-col>

              <v-col cols="7">
                <label for="">Description</label>
                <v-text-field type="text" name="description" v-model="model.description" />
              </v-col>
              <v-col cols="12">
                <h4 class="mb-3">Background Layout</h4>
                <UploadOneImage :image="{ src: model.backgroundSrc }" @callback="getImageUpLoad" />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-divider class=""></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="" @click="showed = false"> Cancel </v-btn>
        <v-btn @click="saveAndConfig">Save and config</v-btn>
        <v-btn color="primary" @click="onSubmit">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { bookApi } from '@/apis/book';
import DataModal from '@/mixins/data-modal';
import { required, minLength, maxLength, between } from 'vuelidate/lib/validators';

export default {
  mixins: [DataModal],
  props: ['book'],
  data() {
    return {
      layoutId: null,
      page: null,
      numberOfCharacter: 0,
    };
  },
  validations: {
    numberOfCharacter: {
      required,
    },
    model: {
      code: {
        required,
      },
    },
  },
  methods: {
    clearCondition() {
      //reset
      this.model.conditions = [{}];
    },
    getImageUpLoad(images) {
      this.model.backgroundSrc = images.src;
      this.model.background = images._id;
      this.$forceUpdate();
      console.log(this.model.backgroudSrc, 'images');
    },
    async saveAndConfig() {
      let done = await this.onSave();
      if (!done) return;
      this.showed = false;
      if (!this.layoutId) return;
      this.$router.push({ name: 'book2-editor', params: { bookId: this.book, layoutId: this.layoutId } });
    },
    setPage(page) {
      this.page = page;
    },
    async getModel(id) {
      try {
        let res = await bookApi.getLayout(this.book, id);
        return res.data.pageLayout;
      } catch (err) {
        return null;
      }
    },
    async onSave() {
      let layout = {
        code: this.model.code,
        description: this.model.description,
        //isDefault: this.model.isDefault ? true : false,
        isDefault: true,
        backgroundSrc: this.model.backgroundSrc,
        background: this.model.background,
        conditions: this.model.conditions,
      };
      try {
        let res;
        if (this.model._id) {
          layout.page = this.model.page;
          res = await bookApi.updateLayout(this.book, this.model._id, layout);
        } else {
          if (!this.page || !this.page._id) return false;
          layout.page = this.page._id;
          res = await bookApi.createLayout(this.book, layout);
        }
        if (res) this.layoutId = res.data.pageLayout?._id;
        return true;
      } catch (err) {
        this.errors = err;
        return false;
      }
    },
  },
};
</script>
