<template>
  <v-dialog persistent v-model="showed" width="900">
    <v-card relative>
      <v-card-title class="headline">
        {{ model._id ? 'Update character' : 'Create character' }}
      </v-card-title>
      <div class="close-icon" @click="clickCancel">
        <v-icon>fas fa-times</v-icon>
      </div>
      <v-divider class=""></v-divider>
      <v-card-text>
        <v-container fluid class="form-component">
          <v-form>
            <v-row>
              <v-col cols="6" class="pb-0">
                <label for="">Title</label>
                <v-text-field
                  :error-messages="invalidSubmit && !$v.model.title.required ? $t('Title is required') : ''"
                  type="text"
                  name="title"
                  label=""
                  v-model="model.title"
                />
              </v-col>
              <v-col cols="6" class="pb-0">
                <label for="">Code</label>
                <v-text-field
                  :error-messages="invalidSubmit && !$v.model.code.required ? $t('Code is required') : ''"
                  type="text"
                  name="code"
                  label=""
                  v-model="model.code"
                />
              </v-col>
              <v-col cols="12">
                <label for="">Description</label>
                <v-text-field hide-details="" type="text" name="description" label="" v-model="model.description" />
              </v-col>
              <v-col cols="12">
                <label for="">Character group</label>
                <v-select
                  hide-details=""
                  placeholder=""
                  dense
                  :items="characterGroupList"
                  item-value="_id"
                  item-text="title"
                  v-model="model.characterGroup"
                ></v-select>
              </v-col>
              <v-col cols="4">
                <label for="">Must input name</label>
                <v-checkbox hide-details="" name="allowName" label="" v-model="allowName" />
              </v-col>
              <v-col cols="4">
                <label for="">Required</label>
                <v-checkbox hide-details="" name="required" label="" v-model="model.required" />
              </v-col>
              <v-col cols="4">
                <label for="">Active</label>
                <v-checkbox hide-details="" name="activated" label="" v-model="model.activated" />
              </v-col>

              <v-col cols="3" v-if="allowName">
                <label for="">Name length (min):</label>
                <v-text-field
                  hide-details=""
                  type="number"
                  name="minLength"
                  label=""
                  v-model="propertyName.minLength"
                />
              </v-col>
              <v-col cols="3" v-if="allowName">
                <label for="">Name length (max):</label>
                <v-text-field
                  hide-details=""
                  type="number"
                  name="maxLength"
                  label=""
                  v-model="propertyName.maxLength"
                />
              </v-col>
              <v-col cols="3"></v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <label>Character avatars</label>
              </v-col>
              <!-- {{ avatars }} -->
              <!-- {{ defaultForm.propertyAvatar }} -->
              <!-- <div class="close-icon" @click="showed = false">
              <v-icon>fas fa-times</v-icon>
            </div> -->
              <v-container fluid>
                <v-form>
                  <avatar-group
                    v-for="(group, idx) in defaultForm.propertyAvatar"
                    :key="idx"
                    :idx="idx"
                    :dataCharacter="defaultForm"
                    :delete="deleteGroup"
                  />
                </v-form>
                <v-btn @click="addGroup" class="mt-2"><i class="fas fa-plus mr-1 "></i>Add group</v-btn>
              </v-container>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-divider class=""></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="" @click="clickCancel()"> Cancel </v-btn>
        <v-btn :loading="isLoadingButton" color="primary" @click="onSave">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import { bookApi } from '@/apis/book';
import DataModal from '@/mixins/data-modal';
import { required, minLength, maxLength, between } from 'vuelidate/lib/validators';
import AvatarGroup from './AvatarGroup.vue';

export default {
  components: { AvatarGroup },
  mixins: [DataModal],
  props: ['book'],
  data() {
    return {
      invalidSubmit: false,
      characterGroupList: [],
      isLoadingButton: false,
      allowName: false,
      defaultForm: {
        title: '',
        code: '',
        description: '',
        propertyName: '',
        propertyAvatar: [],
      },
      propertyName: {
        minLength: 2,
        maxLength: 12,
      },
    };
  },
  validations: {
    model: {
      title: {
        required,
      },
      code: {
        required,
      },
    },
  },

  watch: {
    model() {
      // this.allowName = !_.isEmpty(this.model?.propertyName);
      this.propertyName = Object.assign({ minLength: 2, maxLength: 12 }, this.model?.propertyName);
    },
  },
  computed: {
    avatars() {
      return this.model?.propertyAvatar || [];
    },
  },
  created() {
    this.defaultForm.propertyAvatar = this.model?.propertyAvatar || [];
    this.defaultForm.required = false;
  },
  mounted() {
    this.getCharacterGroups();
  },
  methods: {
    clickCancel() {
      this.showed = false;
      this.invalidSubmit = false;
      this.$v.model.$reset;
    },
    async getCharacterGroups() {
      try {
        let res = await bookApi.getGroups(this.book);
        this.characterGroupList = res.data.characterGroups;
      } catch (err) {
        this.characterGroupList = [];
      }
    },
    deleteGroup(idx) {
      // if (idx > this.defaultForm.propertyAvatar.length) return;
      this.defaultForm.propertyAvatar.splice(idx, 1);
    },
    addGroup() {
      this.defaultForm.propertyAvatar.push({
        code: '',
        name: '',
        data: [],
      });
    },
    async getModel(id) {
      try {
        let res = await bookApi.getCharacter(this.book, id);
        console.log('res.data.character', res.data.character);
        this.defaultForm.propertyAvatar = res.data.character.propertyAvatar;
        return res.data.character;
      } catch (err) {
        return null;
      }
    },
    async onSave() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.invalidSubmit = true;
      }
      if (!this.$v.$invalid) {
        let character = {
          code: this.model.code,
          title: this.model.title,
          description: this.model.description,
          propertyName: this.allowName ? this.propertyName : null,
          required: this.model.required || false,
          activated: this.model.activated,
          propertyAvatar: this.defaultForm.propertyAvatar,
          characterGroup: this.model.characterGroup,
        };
        try {
          if (this.model._id) {
            await bookApi.updateCharacter(this.book, this.model._id, character);
          } else {
            await bookApi.createCharacter(this.book, character);
          }
          this.showed = false;
          this.$parent.loadData();
          return true;
        } catch (err) {
          this.errors = err;
          return false;
        }
      }
    },
  },
};
</script>
<style scoped>
.v-text-field__details {
}
</style>
