<template>
  <v-dialog persistent v-model="showed" width="720">
    <v-card relative>
      <v-card-title>Character avatars</v-card-title>
      <div class="close-icon" @click="showed = false">
        <v-icon>fas fa-times</v-icon>
      </div>
      <v-container fluid>
        <v-form v-if="model">
          <AvatarGroup v-for="(group, idx) in avatars" :key="idx" :idx="idx" :delete="deleteGroup" />
        </v-form>
      </v-container>
      <v-card-actions>
        <v-btn @click="addGroup" class="ml-3"><i class="fas fa-plus mr-1"></i>Add group</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="" @click="showed = false"> Cancel </v-btn>
        <v-btn :loading="isLoadingButton" color="primary" @click="onSave">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { bookApi } from '@/apis/book';
import { required, minLength, maxLength, between } from 'vuelidate/lib/validators';
import AvatarGroup from './AvatarGroup';

export default {
  components: { AvatarGroup },
  props: ['book'],
  data() {
    return {
      isLoadingButton: false,
      showed: false,
    };
  },
  computed: {
    model() {
      return this.$store.state.character.model;
    },
    avatars() {
      return this.model?.propertyAvatar || [];
    },
  },
  watch: {
    showed() {
      if (!this.showed) this.release();
    },
  },
  methods: {
    load(model) {
      this.$store.commit('character/load', model);
    },
    release() {
      this.$store.commit('character/release');
    },
    addGroup() {
      if (!this.model) return;
      if (!this.model.propertyAvatar) this.model.propertyAvatar = [];
      this.model.propertyAvatar.push({
        code: '',
        name: '',
        data: [],
      });
    },
    deleteGroup(idx) {
      if (!this.model || !this.model.propertyAvatar) return;
      if (idx > this.model.propertyAvatar.length) return;
      this.model.propertyAvatar = this.model.propertyAvatar.splice(idx, 1);
    },
    async show(id) {
      this.release();
      if (!id || id == '') return;
      this.showed = true;
      await this.loadData(id);
    },
    async loadData(id) {
      try {
        let res = await bookApi.getCharacter(this.book, id);
        this.load(res.data.character);
      } catch (err) {
        this.release();
      }
    },
    async onSave() {
      if (!this.model) return;
      let data = {
        code: this.model.code,
        title: this.model.title,
        propertyName: this.model.propertyName,
        propertyAvatar: this.model.propertyAvatar,
        required: this.model.required === undefined ? false : true,
        description: this.model.description,
        book: this.model.book,
        activated: this.model.activated,
      };
      try {
        await bookApi.updateCharacter(this.book, this.model._id, data);
        this.showed = false;
      } catch (err) {
        this.errors = err;
      }
    },
  },
};
</script>
