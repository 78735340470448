<template>
  <v-dialog persistent v-model="showed" width="720">
    <v-card relative>
      <v-card-title class="headline">
        {{ model._id ? 'Update' : 'Create' }}
      </v-card-title>
      <div class="close-icon" @click="clickCancel">
        <v-icon>fas fa-times</v-icon>
      </div>
      <v-divider class=""></v-divider>
      <v-card-text>
        <v-container class="form-component">
          <v-form>
            <v-row>
              <v-col cols="6" class="pb-0">
                <label for="">Title</label>
                <v-text-field
                  :error-messages="invalidSubmit && !$v.model.title.required ? $t('Title is required') : ''"
                  type="text"
                  name="title"
                  label=""
                  v-model="model.title"
                />
              </v-col>
              <v-col cols="6" class="pb-0">
                <label for="">Code</label>
                <v-text-field
                  :error-messages="invalidSubmit && !$v.model.code.required ? $t('Code is required') : ''"
                  type="text"
                  name="code"
                  label=""
                  v-model="model.code"
                />
              </v-col>
              <v-col cols="12">
                <label for="">Description</label>
                <v-text-field hide-details="" type="text" name="description" label="" v-model="model.description" />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-divider class=""></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="" @click="clickCancel()"> Cancel </v-btn>
        <v-btn :loading="isLoadingButton" color="primary" @click="onSave">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { bookApi } from '@/apis/book';
import DataModal from '@/mixins/data-modal';
import { required, minLength, maxLength, between } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      invalidSubmit: false,
      isLoadingButton: false,
    };
  },
  mixins: [DataModal],
  props: ['book'],
  validations: {
    model: {
      title: {
        required,
      },
      code: {
        required,
      },
    },
  },
  methods: {
    async getModel(id) {
      try {
        let res = await bookApi.getGroup(this.book, id);
        return res.data.characterGroup;
      } catch (err) {
        return null;
      }
    },
    clickCancel() {
      this.showed = false;
      this.invalidSubmit = false;
      this.$v.model.$reset;
    },
    async onSave() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.invalidSubmit = true;
      }
      if (!this.$v.$invalid) {
        let group = {
          code: this.model.code,
          title: this.model.title,
          description: this.model.description,
        };
        try {
          if (this.model._id) {
            await bookApi.updateGroup(this.book, this.model._id, group);
          } else {
            await bookApi.createGroup(this.book, group);
          }
          this.showed = false;
          this.$parent.loadData();
          return true;
        } catch (err) {
          this.errors = err;
          return false;
        }
      }
    },
  },
};
</script>
