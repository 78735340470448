<template>
  <div>
    <v-row>
      <v-col style="padding:0px;padding-right:-12px;padding-bottom:12px" cols="12" class="d-flex pl-0 justify-end">
        <v-btn color="primary" @click="onCreate()"><i class="fas fa-plus mr-1"></i> Add character</v-btn>
      </v-col>
      <v-col cols="12" class="elevation-1">
        <v-data-table
          :headers="columns"
          :items="characters"
          :hide-default-footer="false"
          :hide-default-header="false"
          disable-pagination
        >
          <template v-slot:[`item.title`]="{ item }">
            <span v-for="avatarList in item.propertyAvatar" :key="avatarList.code">
              <v-avatar tile size="40" color="#dddd" class="text-center pointer mr-3">
                <img
                  :src="
                    avatarList.data && avatarList.data.length > 0
                      ? imageHelpers.url(avatarList.data[0].dataValue, { width: 40, height: 40 })
                      : '@/assets/images/no-image.png'
                  "
                  alt=""
                  srcset=""
                />
              </v-avatar>
            </span>
            <span>{{ item.title }}</span>
          </template>
          <template v-slot:[`item.propertyName`]="{ item }">
            <span v-if="item.propertyName"><i class="fas fa-question-circle"></i></span>
            <span v-if="!item.propertyName"><i class="fas fa-times"></i></span>
          </template>
          <template v-slot:[`item.propertyAvatar`]="{ item }">
            <v-btn @click="$refs.avatarModal.show(item._id)">
              <i v-if="item.propertyAvatar && item.propertyAvatar.length > 0" class="fas fa-users mr-1"></i>
              <i v-if="!item.propertyAvatar || item.propertyAvatar.length == 0" class="fas fa-times mr-1"></i>
              {{ item.avatarCount }}
            </v-btn>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="onEdit(item._id)"><i class="fas fa-edit"></i></v-btn>
            <v-btn icon @click="onDelete(item._id)"><i class="fas fa-trash"></i></v-btn>
          </template>
        </v-data-table>
      </v-col>
      <!-- <v-col cols="1" class="d-flex pl-0">
        <v-btn color="primary" class="" @click="onCreate()"><i class="fas fa-plus"></i></v-btn>
      </v-col> -->
    </v-row>
    <CharacterModal ref="modal" :book="book" :onSaved="loadData" />
    <AvatarModal ref="avatarModal" :book="book" :onSaved="loadData" />
    <delete-action :content="`Character`" :title="`Delete`" />
  </div>
</template>

<script>
import utilities from '@/helpers/utilities';
import imageHelpers from '@/helpers/image';
import { imageFake } from '@/const/image';
import { bookApi } from '@/apis/book';
import CharacterModal from './CharacterModal';
import AvatarModal from './AvatarModal';

export default {
  components: { CharacterModal, AvatarModal },
  props: ['book'],
  data() {
    return {
      utilities,
      idItem: '',
      showDialogDelete: false,
      imageHelpers,
      imageFake,
      characters: [],
      columns: [
        { text: 'TITLE', value: 'title' },
        { text: 'CODE', value: 'code' },
        // { text: 'NAME', value: 'propertyName' },
        // { text: 'AVATAR', value: 'propertyAvatar' },
        { align: 'right', value: 'actions' },
      ],
    };
  },
  methods: {
    onDelete(item) {
      this.idItem = item;
      this.showDialogDelete = true;
    },
    async submitDelete() {
      try {
        await bookApi.deleteCharacter(this.$route.params.id, this.idItem);
        this.loadData();
        this.showDialogDelete = false;
      } catch (error) {
        console.log(error);
      }
    },
    async loadData() {
      try {
        let res = await bookApi.getCharacters(this.book);
        let characters = res.data.characters || [];
        this.characters = characters.map(c => {
          let count = 0;
          if (c.propertyAvatar) {
            for (let g of c.propertyAvatar) {
              count += g?.data?.length || 0;
            }
          }
          return {
            ...c,
            avatarCount: count,
          };
        });
      } catch (err) {
        this.characters = [];
      }
    },
    onCreate() {
      this.$refs.modal.show();
    },
    onEdit(id) {
      this.$refs.modal.show(id);
    },
  },
  created() {
    this.loadData();
  },
};
</script>
