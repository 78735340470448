<template>
  <v-dialog persistent v-model="showed" width="720">
    <v-card relative>
      <v-card-title class="headline">
        {{ model._id ? 'Update chapter' : 'Create chapter' }}
      </v-card-title>
      <div class="close-icon" @click="showed = false">
        <v-icon>fas fa-times</v-icon>
      </div>
      <v-divider class=""></v-divider>
      <v-card-text>
        <v-container class="form-component">
          <v-form>
            <v-row>
              <v-col cols="6">
                <label for="">Title</label>
                <v-text-field hide-details type="text" name="title" v-model="model.title" />
              </v-col>
              <v-col cols="6">
                <label for="">Code</label>
                <v-text-field
                  :error-messages="invalidSubmit && !$v.model.code.required ? $t('Code is required') : ''"
                  type="text"
                  name="code"
                  :hide-details="!(invalidSubmit && !$v.model.code.required)"
                  v-model="model.code"
                />
              </v-col>
              <v-col cols="6">
                <label for="">From page</label>
                <v-text-field
                  :error-messages="
                    invalidSubmit && !$v.model.fromPageNumber.required ? $t('From Page is required') : ''
                  "
                  type="number"
                  :hide-details="!(invalidSubmit && !$v.model.fromPageNumber.required)"
                  name="fromPageNumber"
                  v-model="model.fromPageNumber"
                />
              </v-col>
              <v-col cols="6">
                <label for="">To page</label>
                <v-text-field
                  :error-messages="invalidSubmit && !$v.model.toPageNumber.required ? $t('To Page is required') : ''"
                  type="number"
                  :hide-details="!(invalidSubmit && !$v.model.toPageNumber.required)"
                  name="toPageNumber"
                  v-model="model.toPageNumber"
                />
              </v-col>
              <v-col cols="12">
                <label for="">Description</label>
                <v-text-field hide-details="" type="text" name="description" v-model="model.description" />
              </v-col>
              <v-col cols="6">
                <label for="">Ordinal</label>
                <v-text-field
                  :error-messages="invalidSubmit && !$v.model.ordinal.required ? $t('Ordinal is required') : ''"
                  type="number"
                  :hide-details="!(invalidSubmit && !$v.model.ordinal.required)"
                  name="ordinal"
                  v-model="model.ordinal"
                />
              </v-col>
              <v-col cols="6">
                <v-checkbox hide-details="" name="activated" label="Active" v-model="model.activated" />
              </v-col>
              <v-col cols="12">
                <h4 class="mb-3">Chapter image</h4>
                <UploadOneImage :image="{ src: model.imageSrc }" @callback="getImageUpLoad" />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-divider class=""></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="" @click="clickCancel()"> Cancel </v-btn>
        <v-btn :loading="isLoadingButton" color="primary" @click="onSave()">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { bookApi } from '@/apis/book';
import DataModal from '@/mixins/data-modal';
import { required, minLength, maxLength, between } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      isLoadingButton: false,
      invalidSubmit: false,
      model: {
        code: '',
        title: '',
        fromPageNumber: '',
        toPageNumber: '',
        description: '',
        ordinal: '',
        activated: '',
      },
    };
  },
  mixins: [DataModal],
  props: ['book'],
  validations: {
    model: {
      // title: {
      //   required,
      // },
      code: {
        required,
      },
      fromPageNumber: {
        required,
      },
      toPageNumber: {
        required,
      },
      ordinal: {
        required,
      },
    },
  },
  methods: {
    getImageUpLoad(images) {
      this.model.imageSrc = images.src;
      this.model.image = images._id;
      this.$forceUpdate();
      console.log(this.model.backgroudSrc, 'images');
    },
    clickCancel() {
      this.showed = false;
      this.invalidSubmit = false;
      this.$v.model.$reset;
    },
    async getModel(id) {
      try {
        let res = await bookApi.getChapter(this.book, id);
        return res.data.chapter;
      } catch (err) {
        return null;
      }
    },
    async onSave() {
      this.$v.$touch();
      if (
        this.$v.model.code.$invalid ||
        this.$v.model.ordinal.$invalid ||
        this.$v.model.fromPageNumber.$invalid ||
        this.$v.model.toPageNumber.$invalid
      ) {
        this.invalidSubmit = true;
      }
      if (!this.$v.$invalid) {
        let chapter = {
          code: this.model.code,
          title: this.model.title,
          fromPageNumber: this.model.fromPageNumber,
          toPageNumber: this.model.toPageNumber,
          description: this.model.description,
          ordinal: parseInt(this.model.ordinal),
          activated: this.model.activated,
          image: this.model.image,
          imageSrc: this.model.imageSrc,
        };
        try {
          if (this.model._id) {
            await bookApi.updateChapter(this.book, this.model._id, chapter);
          } else {
            await bookApi.createChapter(this.book, chapter);
          }
          this.$parent.loadData();
          this.showed = false;
          return true;
        } catch (err) {
          this.errors = err;
          return false;
        }
      }
    },
  },
};
</script>
