<template>
  <div>
    <v-row>
      <v-col style="padding:0px;padding-right:-12px;padding-bottom:12px" cols="12" class="d-flex pl-0 justify-end">
        <v-btn color="primary" @click="onCreate()"><i class="fas fa-plus mr-1"></i> Add character group</v-btn>
      </v-col>
      <v-col cols="12" class="elevation-1">
        <v-data-table
          :headers="columns"
          :items="groups"
          :hide-default-footer="false"
          :hide-default-header="false"
          disable-pagination
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="onEdit(item._id)"><i class="fas fa-edit"></i></v-btn>
            <v-btn icon @click="onDelete(item._id)"><i class="fas fa-trash"></i></v-btn>
          </template>
        </v-data-table>
      </v-col>
      <!-- <v-col cols="1" class="d-flex pl-0">
        <v-btn color="primary" @click="onCreate()"><i class="fas fa-plus"></i></v-btn>
      </v-col> -->
    </v-row>
    <GroupModal ref="modal" :book="book" :onSaved="loadData" />
    <delete-action :content="`Character Group`" :title="`Delete`" />
  </div>
</template>

<script>
import { bookApi } from '@/apis/book';
import GroupModal from './GroupModal';

export default {
  components: { GroupModal },
  props: ['book'],
  data() {
    return {
      showDialogDelete: false,
      idItem: '',
      groups: [],
      columns: [
        { text: 'TITLE', value: 'title' },
        { text: 'DESCRIPTION', value: 'description' },
        { align: 'right', value: 'actions' },
      ],
    };
  },
  methods: {
    async loadData() {
      try {
        let res = await bookApi.getGroups(this.book);
        this.groups = res.data.characterGroups;
      } catch (err) {
        this.groups = [];
      }
    },
    onCreate() {
      this.$refs.modal.show();
    },
    onDelete(item) {
      this.idItem = item;
      this.showDialogDelete = true;
    },
    async submitDelete() {
      try {
        await bookApi.deleteGroup(this.$route.params.id, this.idItem);
        await this.loadData();
        this.showDialogDelete = false;
      } catch (error) {
        console.log(error);
      }
    },
    onEdit(id) {
      this.$refs.modal.show(id);
    },
  },
  created() {
    this.loadData();
  },
};
</script>
