<template>
  <v-dialog persistent v-model="showed" width="720">
    <v-card relative>
      <v-card-title class="headline">
        {{ model._id ? 'Update page' : 'Create page' }}
      </v-card-title>
      <div class="close-icon" @click="clickCancel">
        <v-icon>fas fa-times</v-icon>
      </div>
      <v-divider></v-divider>
      <v-container>
        <v-form>
          <v-row class="form-component">
            <v-col cols="6">
              <label for="">Code</label>
              <v-text-field
                :error-messages="invalidSubmit && !$v.model.code.required ? $t('Code is required') : ''"
                type="text"
                name="code"
                v-model="model.code"
              />
            </v-col>
            <v-col cols="6">
              <label for="">Page number</label>
              <v-text-field
                :error-messages="invalidSubmit && !$v.model.pageNumber.required ? $t('Page number is required') : ''"
                type="number"
                name="pageNumber"
                v-model="model.pageNumber"
              />
            </v-col>
            <!-- <v-col cols="12">
              <label for="">Description</label>
              <v-textarea type="text" name="description" label="" rows="2" v-model="model.description" />
            </v-col> -->
            <!-- <v-col cols="6">
              <label for="">Background</label>
              <ImageUpload :src="model.backgroundSrc" ref="background" />
            </v-col> -->
            <!-- <v-col cols="6">
              <label for="">Active</label>
              <v-checkbox name="activated" label="Active" v-model="model.activated" />
            </v-col> -->
          </v-row>
        </v-form>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="" @click="clickCancel"> Cancel </v-btn>
        <v-btn :loading="isLoadingButton" color="primary" @click="onSave">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { bookApi } from '@/apis/book';
import DataModal from '@/mixins/data-modal';
import { required, minLength, maxLength, between } from 'vuelidate/lib/validators';
import ImageUpload from './ImageUpload';

export default {
  // components: { ImageUpload },
  data() {
    return {
      isLoadingButton: false,
      invalidSubmit: false,
    };
  },
  mixins: [DataModal],
  props: ['book'],
  validations: {
    model: {
      code: {
        required,
      },
      pageNumber: {
        required,
      },
    },
  },
  methods: {
    clickCancel() {
      this.showed = false;
      this.invalidSubmit = false;
      this.$v.model.$reset;
    },
    async getModel(id) {
      try {
        let res = await bookApi.getPage(this.book, id);
        return res.data.page;
      } catch (err) {
        return null;
      }
    },
    async onSave() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.invalidSubmit = true;
      }
      if (!this.$v.$invalid) {
        let page = {
          code: this.model.code,
          pageNumber: parseInt(this.model.pageNumber),
          description: this.model.description,
          activated: this.model.activated,
        };
        // let bg = this.$refs.background.getData();
        // if (bg.imageId) {
        //   page.background = bg.imageId;
        //   page.backgroundSrc = bg.imageSrc;
        // }
        try {
          if (this.model._id) {
            await bookApi.updatePage(this.book, this.model._id, page);
          } else {
            let res = await bookApi.createPage(this.book, page);
            page = res.data?.page;
            if (!page) return true;
            let layout = {
              page: page._id,
              code: page.code + '-0',
              description: 'Default layout',
              isDefault: true,
            };
            await bookApi.createLayout(this.book, layout);
          }
          this.showed = false;
          this.$parent.loadData();
          return true;
        } catch (err) {
          this.errors = err;
          return false;
        }
      }
    },
  },
};
</script>
