<template>
  <div class="">
    <v-row>
      <v-col style="padding:0px;padding-right:12px" cols="12" class="d-flex pl-0 justify-end">
        <v-btn color="primary" @click="$refs.modal.show()"><i class="fas fa-plus mr-1"></i>{{ $t('Add page') }}</v-btn>
      </v-col>
      <v-col cols="12" v-if="pages && pages.length > 0">
        <v-card v-for="page in pages" :key="page._id" class="mb-1 relative">
          <v-container fluid>
            <v-row no-gutters class="pt-4">
              <v-col cols="1">
                <span class="book-page-number">Page {{ page.pageNumber }}</span>
                <a
                  style="color: rgba(0,0,0,.54)"
                  class="invent mr-5 ml-2"
                  title="Update page"
                  @click="$refs.modal.show(page._id)"
                  ><i class="fas fa-edit"></i>
                </a>
                <a style="color: rgba(0,0,0,.54)" color="red" title="Delete page" @click="onDelete(page._id)"
                  ><i class="fas fa-trash"></i>
                </a>
              </v-col>
              <v-col cols="11">
                <div class="page-pane-layout-tile">Layout</div>
                <LayoutList @loadData="loadData" :layouts="page.pageLayouts" :edit="editLayout" :open="openLayout" />
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions style="margin-left:100px">
            <a color="" class="invent mr-7" @click="createLayout(page)"><i class="fas fa-plus mr-1"> </i> Add layout</a>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col v-if="pages && pages.length === 0 && !isLoading" cols="12">
        <empty />
      </v-col>
    </v-row>
    <PageModal ref="modal" :book="book" :onSaved="loadData" />
    <LayoutModal ref="layoutModal" :book="book" :onSaved="loadData" />
    <delete-action :content="`Page`" :title="`Delete`" />
  </div>
</template>

<script>
import _ from 'lodash';
import { bookApi } from '@/apis/book';
import PageModal from './PageModal';
import LayoutModal from './LayoutModal';
import LayoutList from './LayoutList';

export default {
  components: { PageModal, LayoutModal, LayoutList },
  props: ['book'],
  data() {
    return {
      showDialogDelete: false,
      idItem: '',
      pages: [],
      isLoading: false,
    };
  },
  methods: {
    onDelete(item) {
      this.idItem = item;
      this.showDialogDelete = true;
    },
    async submitDelete() {
      try {
        await bookApi.deletePage(this.$route.params.id, this.idItem);
        await this.loadData();
        this.showDialogDelete = false;
      } catch (error) {
        console.log(error);
      }
    },
    async loadData() {
      this.isLoading = true;
      let layouts = {};
      try {
        let res = await bookApi.getLayouts(this.book);
        layouts = _.groupBy(res.data.pageLayouts, 'page');
      } catch (err) {
        this.layouts = [];
      }
      try {
        let res = await bookApi.getPages(this.book);
        let pages = res.data.pages || [];
        this.pages = pages.map(page => {
          return {
            ...page,
            pageLayouts: layouts[page._id] || [],
          };
        });
        this.isLoading = false;
      } catch (err) {
        this.pages = [];
      }
    },
    onItemClick(id) {
      this.$refs.modal.show(id);
    },
    openLayout(id) {
      this.$router.push({ name: 'book2-editor', params: { bookId: this.book, layoutId: id } });
    },
    createLayout(page) {
      this.$refs.layoutModal.setPage(page);
      this.$refs.layoutModal.show({
        code: '',
        description: '',
        //isDefault: this.model.isDefault ? true : false,
        isDefault: true,
        conditions: [{}],
      });
    },
    editLayout(id) {
      this.$refs.layoutModal.setPage(null);
      this.$refs.layoutModal.show(id);
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style scoped>
.delete-page {
  color: red;
  position: absolute;
  top: 10px;
  right: -5px;
}
.relative {
  position: relative;
}
.book-page-number {
  display: inline-block;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.page-pane-layout-tile {
  font-weight: bold;
  font-size: 90%;
  margin-left: 1.1em;
}
</style>
