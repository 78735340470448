<template>
  <div class="page-book" v-if="book">
    <v-container class="">
      <v-row>
        <v-col cols="12" class="page-header">
          <span class="page-title">
            {{ product.title }}
            <span class="page-subtitle ml-2">{{ book.code }}</span>
          </span>
        </v-col>
        <v-col cols="12" class="d-flex align-center">
          <v-tabs v-model="tab">
            <v-tab v-for="item in tabs" :key="item">{{ item }}</v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <PagePane v-if="tabName === 'pages'" :book="book._id" />
      <ChapterPane v-if="tabName === 'chapters'" :book="book._id" />
      <CharacterPane v-if="tabName === 'characters'" :book="book._id" />
      <GroupPane v-if="tabName === 'character-groups'" :book="book._id" />
    </v-container>
  </div>
</template>

<script>
import { productApi } from '@/apis/product';
import { bookApi } from '@/apis/book';
import PagePane from './components/PagePane';
import ChapterPane from './components/ChapterPane';
import CharacterPane from './components/CharacterPane';
import GroupPane from './components/GroupPane';

export default {
  components: { PagePane, ChapterPane, CharacterPane, GroupPane },
  data() {
    return {
      book: null,
      tabs: ['pages', 'chapters', 'characters', 'character-groups'],
      tab: 0,
    };
  },
  computed: {
    product() {
      return this.book?.product || {};
    },
    tabName() {
      return this.tab >= this.tabs.length ? null : this.tabs[this.tab];
    },
  },
  methods: {
    async loadData() {
      try {
        let res = await bookApi.getBook(this.$route.params.id);
        let book = res.data.book;
        if (book && book.product) {
          res = await productApi.getById(book.product);
        }
        this.book = {
          ...book,
          product: res.data.product,
        };
      } catch (err) {
        this.book = null;
      }
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style>
.page-subtitle {
  font-size: 0.5em;
}
</style>
