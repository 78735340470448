<template>
  <v-card class="dkm mb-2">
    <v-form>
      <v-container fluid class="form-component">
        <v-row>
          <v-col cols="4" class="pt-0 pb-0">
            <label for="">Code</label>
            <v-text-field type="text" name="code" v-model="model.code" />
          </v-col>
          <v-col cols="4" class="pt-0 pb-0">
            <label for="">Name</label>

            <v-text-field type="text" v-model="model.name" />
          </v-col>
          <v-col cols="4" class="pt-0 pb-0 d-flex">
            <v-spacer />
            <v-btn @click="onDelete"><i class="fas fa-trash"></i></v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <AvatarGrid cols="2" :group="model" :changed="onChanged" />
        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import AvatarGrid from './AvatarGrid';

export default {
  components: { AvatarGrid },
  props: ['idx', 'delete', 'dataCharacter'],
  computed: {
    model() {
      // let char = this.$store.state.character.model;
      let avatars = this.dataCharacter?.propertyAvatar || [];
      return this.idx < avatars.length ? avatars[this.idx] : { data: [] };
    },
  },
  methods: {
    onDelete() {
      if (typeof this.delete === 'function') {
        this.delete(this.idx);
      }
    },
    onChanged(data) {
      this.model.data = data;
    },
  },
};
</script>
