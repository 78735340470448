<template>
  <div>
    <v-data-table
      :headers="columns"
      :items="layouts"
      :hide-default-footer="true"
      :hide-default-header="true"
      disable-paginatio
      condensed
    >
      <template v-slot:[`item.code`]="{ item }">
        <a @click="onOpen(item._id)">
          <v-avatar tile size="40" color="#dddd" class="text-center pointer mr-3">
            <img
              :src="
                item.backgroundSrc
                  ? imageHelpers.url(item.backgroundSrc, { width: 40, height: 40 })
                  : '@/assets/images/no-image.png'
              "
              alt=""
              srcset=""
            />
          </v-avatar>
          <span class="invent">{{ item.code }}</span>
        </a>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon @click="onEdit(item._id)"><i class="fas fa-edit"></i></v-btn>
        <v-btn icon @click="onDelete(item._id)"><i class="fas fa-trash"></i></v-btn>
      </template>
    </v-data-table>
    <delete-action :content="`Layout`" :title="`Delete`" />
  </div>
</template>

<script>
import utilities from '@/helpers/utilities';
import imageHelpers from '@/helpers/image';
import { imageFake } from '@/const/image';
import { bookApi } from '@/apis/book';

export default {
  props: ['layouts', 'delete', 'edit', 'open'],
  data() {
    return {
      showDialogDelete: false,
      utilities,
      imageHelpers,
      imageFake,
      columns: [
        { align: 'left', value: 'code' },
        { align: 'right', value: 'actions' },
      ],
    };
  },
  methods: {
    onDelete(item) {
      this.idItem = item;
      this.showDialogDelete = true;
    },
    async submitDelete() {
      try {
        await bookApi.deleteLayout(this.$route.params.id, this.idItem);
        this.$emit(`loadData`);
        this.showDialogDelete = false;
      } catch (error) {
        console.log(error);
      }
    },
    onEdit(id) {
      if (typeof this.edit === 'function') {
        this.edit(id);
      }
    },
    onOpen(id) {
      if (typeof this.open === 'function') {
        this.open(id);
      }
    },
  },
};
</script>
