var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"d-flex pl-0 justify-end",staticStyle:{"padding":"0px","padding-right":"-12px","padding-bottom":"12px"},attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.onCreate()}}},[_c('i',{staticClass:"fas fa-plus mr-1"}),_vm._v(" Add character")])],1),_c('v-col',{staticClass:"elevation-1",attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.characters,"hide-default-footer":false,"hide-default-header":false,"disable-pagination":""},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._l((item.propertyAvatar),function(avatarList){return _c('span',{key:avatarList.code},[_c('v-avatar',{staticClass:"text-center pointer mr-3",attrs:{"tile":"","size":"40","color":"#dddd"}},[_c('img',{attrs:{"src":avatarList.data && avatarList.data.length > 0
                    ? _vm.imageHelpers.url(avatarList.data[0].dataValue, { width: 40, height: 40 })
                    : '@/assets/images/no-image.png',"alt":"","srcset":""}})])],1)}),_c('span',[_vm._v(_vm._s(item.title))])]}},{key:"item.propertyName",fn:function(ref){
                    var item = ref.item;
return [(item.propertyName)?_c('span',[_c('i',{staticClass:"fas fa-question-circle"})]):_vm._e(),(!item.propertyName)?_c('span',[_c('i',{staticClass:"fas fa-times"})]):_vm._e()]}},{key:"item.propertyAvatar",fn:function(ref){
                    var item = ref.item;
return [_c('v-btn',{on:{"click":function($event){return _vm.$refs.avatarModal.show(item._id)}}},[(item.propertyAvatar && item.propertyAvatar.length > 0)?_c('i',{staticClass:"fas fa-users mr-1"}):_vm._e(),(!item.propertyAvatar || item.propertyAvatar.length == 0)?_c('i',{staticClass:"fas fa-times mr-1"}):_vm._e(),_vm._v(" "+_vm._s(item.avatarCount)+" ")])]}},{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.onEdit(item._id)}}},[_c('i',{staticClass:"fas fa-edit"})]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.onDelete(item._id)}}},[_c('i',{staticClass:"fas fa-trash"})])]}}],null,true)})],1)],1),_c('CharacterModal',{ref:"modal",attrs:{"book":_vm.book,"onSaved":_vm.loadData}}),_c('AvatarModal',{ref:"avatarModal",attrs:{"book":_vm.book,"onSaved":_vm.loadData}}),_c('delete-action',{attrs:{"content":"Character","title":"Delete"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }